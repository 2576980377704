import { PaperProps } from './Paper.types';
import React from 'react';
import clsx from 'clsx';

const Paper = ({ ...componentProps }: PaperProps) => (
  <div
    data-testid="paper"
    children={componentProps.children}
    className={clsx(
      'paper flex flex-col xl:shadow-lg items-center justify-center opacity-80 bg-black text-white p-2 xl:p-4',
      componentProps.className
    )}
  ></div>
);

export default Paper;
