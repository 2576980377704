import React, { useContext } from 'react';
import views, { imprint } from 'views';
import { Link } from 'react-router-dom';
import { RouteContext } from 'containers/Page/Page.context';
import clsx from 'clsx';

/**
 * This component is the footer container.
 * It contains the page navigation and manages it with the help of the {@link RouteContext}.
 */
const Footer = () => {
  const { index } = useContext(RouteContext);

  return (
    <footer
      data-testid="footer"
      className={clsx(
        'footer flex w-full h-1/12 items-center flex-wrap justify-center text-sm',
        'xl:h-auto xl:p-4 xl:fixed xl:z-20 xl:bottom-0 xl:justify-between xl:p-2'
      )}
    >
      <div
        data-testid="footer-hash w-full"
        className={clsx('footer-hash text-gray flex flex-row', 'xl:flex-col')}
      >
        <div className="mx-3 xl:m-0">#Eberswalde</div>
        <div className="mx-3 xl:m-0">#FahrschuleUnternBuchen</div>
        <div className="mx-3 xl:m-0">#AndréZieroth</div>
      </div>

      <Link
        className={clsx(
          'footer-impressum text-gray',
          views[index].theme === 'dark' && 'hover:text-red',
          views[index].theme === 'light' && 'hover:text-white'
        )}
        to={imprint.pathname}
      >
        @{imprint.name}
      </Link>
    </footer>
  );
};

export default Footer;
