import React from 'react';

const Complete = () => (
  <>
    <div className="text-center text-2xl xl:text-5xl">Oh Nein..</div>
    <div className="text-center w-64 ">
      Es gab ein Problem bei deiner Anmeldung. Bitte melde dich telefonisch bei
      uns:
    </div>
    <div className="text-center font-bold ">03334 385256.</div>
  </>
);

export default Complete;
