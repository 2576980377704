import './scrollbar.css';
import React from 'react';
import { ScrollbarProps } from './Scrollbar.types';
import clsx from 'clsx';

const ScrollBar = ({ width, ...componentProps }: ScrollbarProps) => (
  <div
    data-testid="scrollbar"
    className={clsx(
      'scrollbar z-50',
      'h-px xl:h-1 absolute bottom-0 xl:top-0 left-0 right-0',
      componentProps.className
    )}
  >
    <div
      data-testid="scrollbar-cover"
      className={clsx(
        'scrollbar-cover',
        'absolute h-full w-full bg-black z-50',
        `transition-transform duration-500 xl:duration-1000 origin-top-right transform scale-x-${Math.floor(
          100 - width
        )}`
      )}
    ></div>
  </div>
);

export default ScrollBar;
