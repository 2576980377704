import { NavigationItemProps, NavigationProps } from './Navigation.types';
import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';

const NavigationItem = ({
  text,
  dark,
  selected,
  nextActive,
  ...componentProps
}: NavigationItemProps) => (
  <li
    onClick={componentProps.onClick}
    data-testid="navigation-item"
    className={clsx(
      'navigation-item',
      componentProps.className,
      `inline-flex flex-col flex-grow items-center transition-colors duration-150`,
      !selected && `text-gray`,
      dark && 'hover:text-yellow',
      !dark && 'hover:text-white',
      selected && !dark && `text-yellow`,
      selected && dark && `text-yellow`
    )}
  >
    <div
      data-testid="navigation-item-text"
      className={clsx('navigation-item-text')}
    >
      {text}
    </div>
    <div
      data-testid="navigation-item-bar"
      className={clsx(
        'navigation-item-bar',
        `h-1 w-full xl:mt-2 transition-transform transition-colors duration-300 xl:duration-700`,
        !dark && 'bg-yellow',
        dark && 'bg-yellow',
        selected && 'transform scale-x-100',
        !selected && 'transform scale-x-0',
        selected && nextActive === 'right' && 'origin-left',
        !selected && nextActive === 'right' && 'origin-right',
        selected && nextActive === 'left' && 'origin-right',
        !selected && nextActive === 'left' && 'origin-left'
      )}
    />
  </li>
);

const Navigation = ({
  disabled,
  navigate,
  active,
  theme,
  list,
  ...componentProps
}: NavigationProps) => {
  const prevActive = useRef<number>();
  useEffect(() => {
    prevActive.current = active;
  }, [active]);

  return (
    <ul
      data-testid="navigation"
      className={clsx(
        'navigation',
        componentProps.className,
        'list-none flex w-full',
        'xl:w-1/2'
      )}
    >
      {list.map((item, index) => (
        <NavigationItem
          nextActive={
            prevActive.current !== undefined && active > prevActive.current
              ? 'right'
              : 'left'
          }
          className={clsx(
            disabled && 'cursor-not-allowed',
            !disabled && 'cursor-pointer'
          )}
          onClick={() => {
            if (disabled) return;
            navigate(index);
          }}
          dark={theme === 'dark'}
          key={`navigation-item-${index}`}
          selected={active === index}
          text={item}
        />
      ))}
    </ul>
  );
};

export default Navigation;
