import './imprint.css';
import React from 'react';

const Imprint = () => (
  <div className="overflow-y-scroll overflow-x-hidden h-full w-11/12 xl:w-3/5 text-white m-auto py-20">
    <h1 className="text-3xl xl:text-5xl">Impressum</h1>
    <p className="italic mb-20">Informationspflicht laut § 5 TMG. </p>
    <div className="xl:text-lg">
      <p className="my-5">
        Fahrschule Untern Buchen
        <br />
        Eisenbahnstraße 63
        <br />
        16225 Eberswalde
        <br />
        Deutschland
      </p>
      <p className="my-5">
        <span className="underline">Vertreten durch:</span> André Zieroth
      </p>
      <p className="my-5">
        <span className="underline">Tel.:</span> 03334285256
      </p>
      <p className="my-5">
        <span className="underline">Umsatzsteuer-Nummer:</span> 065-279-00679
      </p>
      <p className="my-5">
        <span className="underline">Aufsichtsbehörde:</span>
        <br />
        Zulassungs- und Fahrerlaubnisbehörde Landkreis Barnim
        <br />
        Am Markt 1 / Pfeilstraße
        <br />
        16225 Eberswalde
      </p>
    </div>
    <h2 className="text-xl xl:text-2xl mt-20 mb-5">EU-Streitschlichtung</h2>
    <p>
      Gemäß Verordnung über Online-Streitbeilegung in Verbraucherangelegenheiten
      (ODR-Verordnung) möchten wir Sie über die Online-Streitbeilegungsplattform
      (OS-Plattform) informieren. Verbraucher haben die Möglichkeit, Beschwerden
      an die Online Streitbeilegungsplattform der Europäischen Kommission unter
      <a className="text-yellow" href="http://ec.europa.eu/odr?tid=321181557">
        {' '}
        http://ec.europa.eu/odr?tid=321181557{' '}
      </a>
      zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in
      unserem Impressum. Wir möchten Sie jedoch darauf hinweisen, dass wir nicht
      bereit oder verpflichtet sind, an Streitbeilegungsverfahren vor einer
      Verbraucherschlichtungsstelle teilzunehmen.
    </p>
    <h2 className="text-xl xl:text-2xl mt-10 mb-5">Haftungsausschluss</h2>
    <h3 className="text-lg xl:text-xl mt-5">Haftung für Inhalte</h3>
    <p>
      Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die
      Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch
      keine Gewähr übernehmen. Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG
      für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
      verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch
      nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
      überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
      Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der
      Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon
      unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt
      der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
      von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
      entfernen.
    </p>
    <h3 className="text-lg xl:text-xl mt-5">Haftung für Links</h3>
    <p>
      Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
      Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden
      Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
      Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
      verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung
      auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum
      Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche
      Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte
      einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
      Rechtsverletzungen werden wir derartige Links umgehend entfernen.
    </p>
    <h2 className="text-xl xl:text-2xl mt-10 mb-5">Urheberrecht</h2>
    <p>
      Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
      Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung,
      Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen
      des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen
      Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
      privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf
      dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
      Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
      gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung
      aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
      Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend
      entfernen.
    </p>
    <h2 className="text-xl xl:text-2xl mt-10 mb-5">Datenschutz</h2>
    <p>
      Die Nutzung unserer Webseite ist in der Regel ohne Angabe
      personenbezogener Daten möglich. Soweit auf unseren Seiten
      personenbezogene Daten (beispielsweise Name, Anschrift oder
      eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf
      freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung
      nicht an Dritte weitergegeben. Wir weisen darauf hin, dass die
      Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
      Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem
      Zugriff durch Dritte ist nicht möglich. Der Nutzung von im Rahmen der
      Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur
      Übersendung von nicht ausdrücklich angeforderter Werbung und
      Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die
      Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
      Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
      Spam-Mails, vor.
    </p>
    <p className="my-5">
      Impressum vom
      <a className="text-yellow" href="https://www.impressum-generator.de">
        {' '}
        Impressum Generator{' '}
      </a>
      der
      <a
        className="text-yellow"
        href="https://www.kanzlei-hasselbach.de/standorte/bonn/"
      >
        {' '}
        Kanzlei Hasselbach, Bonn{' '}
      </a>
    </p>
  </div>
);

export default Imprint;
