import './contact.css';
import GoogleMapReact, { Position } from 'google-map-react';
import { Paper } from '../../components';
import React from 'react';
import { apiKey } from '../../firebase';

const officeLocation1 = {
  lat: 52.833647,
  lng: 13.806132,
};

const officeLocation2 = {
  lat: 52.838678,
  lng: 13.73733,
};

const options = {
  // draggable: false,
  gestureHandling: 'cooperative',
  fullscreenControl: false,
  scrollwheel: false,
  disableDoubleClickZoom: true,
  draggableCursor: 'arrow',
  zoomControlOptions: {
    position: 4,
  },
};

const LocationPin = (location: Position) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    className="marker w-10 transform -translate-x-1/2 -translate-y-1/2"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
    />
  </svg>
);

const Contact = () => (
  <div className="contact h-full w-full">
    <GoogleMapReact
      bootstrapURLKeys={{ key: apiKey || '' }}
      options={options}
      defaultCenter={officeLocation1}
      defaultZoom={13}
    >
      <LocationPin {...officeLocation1} />
      <LocationPin {...officeLocation2} />
    </GoogleMapReact>

    <Paper className="contact-info-box absolute bottom-0 left-0 right-0 h-auto text-sm md:text-base xl:h-3/12 xl:w-full">
      <div className="contact-info flex justify-between flex-wrap">
        <div className="inline-flex items-center w-1/2 xl:w-auto">
          <div className="contact-info-title mr-3 text-yellow">Adresse</div>
          <div className="contact-info-text flex-col inline-flex justify-around h-full">
            <p>
              Eisenbahnstraße 31 <br /> 16225 Eberswalde
            </p>
            <p>
              Eberswalder Straße 63 <br /> 16227 Eberswalde
            </p>
          </div>
        </div>

        <div className="inline-flex items-center w-1/2 xl:w-auto p-2 pt-5 ">
          <div className="contact-info-title mr-3 text-yellow">Kontakt</div>
          <div className="contact-info-text flex-col inline-flex justify-around h-full w-1/2">
            <p>
              03334 385256 <br /> kontakt@unternbuchen.de
            </p>
            <p>
              0172 7316142 <br /> andre@unternbuchen.de
            </p>
          </div>
        </div>
      </div>
    </Paper>
  </div>
);

export default Contact;
