import React from 'react';

const Complete = () => (
  <>
    <div className="text-center text-2xl xl:text-5xl">Vielen Dank</div>
    <div className="text-center w-64 ">
      Sobald wir deine Daten erhalten haben, werden wir uns bei dir melden, um
      weitere Schritte zu besprechen.
    </div>
  </>
);

export default Complete;
