import './main.css';
import React, { useContext } from 'react';
import { RouteContext } from 'containers/Page/Page.context';
import clsx from 'clsx';
import views from 'views';

const calcViewPosition = (active: number, index = 0) => {
  const threshold = 3;
  const childRow = index < threshold ? 0 : Math.ceil(index / threshold);
  const currentRow = active < threshold ? 0 : Math.ceil(active / threshold);
  const vertical = childRow > 0 ? 0 : -1;

  const x =
    active < threshold
      ? (index - active) * vertical * -1
      : (index - (threshold - 1)) * vertical * -1;
  const y = childRow - currentRow;

  return {
    x: Math.abs(x),
    y: Math.abs(y),
    xSign: x < 0 ? '-' : '',
    ySign: y < 0 ? '-' : '',
  };
};

const Main = () => {
  const { index: routeIndex } = useContext(RouteContext);

  return (
    <main
      data-testid="main relative"
      className={clsx(
        'main h-9/12 w-screen flex',
        'xl:fixed xl:z-10 xl:h-screen'
      )}
    >
      {views.map((view, viewIndex) => {
        const { xSign, ySign, x, y } = calcViewPosition(routeIndex, viewIndex);
        return (
          <div
            key={`view-${viewIndex}`}
            data-testid="view"
            className={clsx(
              'view h-9/12 w-full',
              'xl:h-full',
              `absolute transition-transform duration-500 xl:duration-1000`,
              `transform ${xSign}translate-x-screen-w-${x}`,
              `transform ${ySign}translate-y-screen-h-${y}`
            )}
          >
            <view.component />
          </div>
        );
      })}
    </main>
  );
};

export default Main;
