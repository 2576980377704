import { AuthContext, RouteContext } from 'containers/Page/Page.context';
import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import Alternative from './Alternative/Alternative.component';
import Complete from './Complete/Complete.component';
import { Paper } from 'components';
import Problem from './Problem/Problem.component';
import Questionnaire from './Questionnaire/Questionnaire.component';
import views from '../config';
import { withRouter } from 'react-router';

const Register = () => {
  const { index } = useContext(RouteContext);
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <Paper
      data-testid="register"
      className="register absolute bottom-4 w-full h-10/12"
    >
      {!isLoggedIn && <Alternative />}
      {isLoggedIn && (
        <Switch>
          <Route
            path={`${views[index].pathname}/abschliessen`}
            component={Complete}
          ></Route>
          <Route
            path={`${views[index].pathname}/problem`}
            component={Problem}
          ></Route>
          <Questionnaire />
        </Switch>
      )}
    </Paper>
  );
};

export default withRouter(Register);
