import { Paper } from '../../components';
import React from 'react';

const AboutUs = () => (
  <Paper
    data-testid="register"
    className="register absolute bottom-4 w-full h-10/12"
  >
    <div className="text-center text-1xl xl:text-2xl">
      Herzlich Willkommen bei
    </div>
    <div className="text-center text-yellow text-2xl xl:text-4xl">
      Fahrschule Untern Buchen
    </div>
    <div className="text-center xl:w-1/2 overflow-scroll">
      Egal welche Führerscheinklasse du erwerben möchtest, bei uns steht dir ein
      kompetentes Team von der Anmeldung bis zur erfolgreichen Absolvierung der
      praktischen Fahrprüfung zur Seite. Geht nicht, gibt es bei uns nicht!
      <br />
      <br />
      Also warum lange suchen? <strong>Fahrschule Untern Buchen</strong>!
    </div>
  </Paper>
);

export default AboutUs;
