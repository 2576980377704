import {
  ButtonControlProps,
  CheckControlProps,
  SelectControlProps,
  TextControlProps,
} from './Control.types';
import React, { ChangeEvent } from 'react';
import { default as ReactSelect } from 'react-select';
import clsx from 'clsx';

const Check = ({ checked, className, ...rest }: CheckControlProps) => (
  <input
    {...rest}
    type="checkbox"
    className={clsx(
      'h-4 w-4 appearance-none border-2 bg-black border-yellow focus:outline-none rounded-none',
      checked && 'bg-yellow',
      className
    )}
  />
);

const Select = ({
  className,
  value = '',
  options = [],
  onChange,
}: SelectControlProps) => {
  const mapReactSelectOption = (o: string) => ({ value: o, label: o });
  return (
    <ReactSelect
      isMulti
      placeholder="auswählen..."
      className={clsx('react-select', className)}
      classNamePrefix="react-select"
      options={options.map(mapReactSelectOption)}
      value={(value || '')
        .split(',')
        .map(mapReactSelectOption)
        .filter(v => v.value !== '')}
      onChange={values => {
        const value = values && values.map(value => value.label).join(',');
        onChange({ target: { value } } as ChangeEvent<any>);
      }}
    />
  );
};

// TODO: extract area to own component
const Text = ({ area, outline, className, ...rest }: TextControlProps) =>
  area ? (
    <textarea
      {...rest}
      className={clsx(
        'uppercase appearance-none text-yellow bg-black focus:outline-none rounded-none',
        !outline && 'border-b-2 border-yellow',
        outline && 'border-2 border-white',
        className
      )}
    />
  ) : (
    <input
      {...rest}
      autoComplete="on"
      className={clsx(
        'appearance-none text-yellow underline bg-black focus:outline-none rounded-none',
        className
      )}
    />
  );

const Button = ({
  disabled,
  outline,
  className,
  ...rest
}: ButtonControlProps) => {
  return (
    <button
      {...rest}
      className={clsx(
        'uppercase py-2 px-4 focus:outline-none',
        [
          !disabled &&
            outline &&
            'border border-yellow text-yellow hover:bg-yellow hover:text-black',
          !disabled && !outline && 'hover:text-yellow',
        ],
        [
          disabled &&
            outline &&
            'border border-gray text-gray cursor-not-allowed',
          disabled && !outline && 'text-gray cursor-not-allowed',
        ],

        className
      )}
    />
  );
};

const Control = { Check, Select, Text, Button };
export default Control;
