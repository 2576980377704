import React from 'react';

const Complete = () => (
  <>
    <div className="text-center text-2xl xl:text-5xl">Anmeldung</div>
    <div className="text-center w-full ">
      <p className="my-5">
        Melde dich per Telefon:
        <span className="text-yellow underline"> 03334 385256,</span>
      </p>
      <p className="my-5">oder besuche uns in unserem Büro:</p>
      <p className="my-5 text-yellow">
        Fahrschule Untern Buchen
        <br />
        Eisenbahnstraße 63
        <br />
        16225 Eberswalde
        <br />
        Deutschland
      </p>
      <p className="my-5">um dich bei uns anzumelden!</p>
      <p className="my-5">Wir freuen uns auf dich.</p>
    </div>
  </>
);

export default Complete;
