import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/auth';
import app from 'firebase/app';

export const apiKey = 'AIzaSyDAgEvzik0HlRZutjOZocRHELDTOJATn7o';

app.initializeApp({
  apiKey,
  authDomain: 'fahrschule-untern-buchen-1927e.firebaseapp.com',
  projectId: 'fahrschule-untern-buchen-1927e',
  storageBucket: 'fahrschule-untern-buchen-1927e.appspot.com',
  messagingSenderId: '13913837082',
  appId: '1:13913837082:web:17e4e1c077f1d922d28ba3',
  measurementId: 'G-46D4DY9M1F',
});

export default app;
