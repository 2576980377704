import React, { ChangeEvent, useEffect, useState } from 'react';
import { defaultDetails, questions } from '../Register.content';
import { readStorage, updateStorage } from 'utils/storageHandler';
import { Control } from 'components';
import { Link } from 'react-router-dom';
import { RegistrationData } from '../Register.types';
import clsx from 'clsx';
import { persistClientAndRequest } from 'utils/databaseHandler';
import { useHistory } from 'react-router-dom';

const Questionnaire = () => {
  const history = useHistory();

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [cachedAnswers, setCachedAnswers] = useState(readStorage());

  useEffect(() => {
    updateStorage(cachedAnswers);
  });

  const questionsCount = Object.keys(questions).length;
  const questionKey = Object.keys(questions)[
    currentQuestion
  ] as keyof RegistrationData;

  return (
    <div
      className={clsx(
        'register-questionnaire h-full flex flex-col items-stretch justify-around text-center',
        'xl:h-1/2'
      )}
    >
      <div className="register-questionnaire-pagination">
        <Control.Button
          onClick={() => setCurrentQuestion(Math.max(currentQuestion - 1, 0))}
          disabled={currentQuestion === 0}
        >
          zurück
        </Control.Button>

        <Control.Button
          outline
          disabled={currentQuestion !== questionsCount - 1}
          onClick={() => {
            if (currentQuestion === questionsCount - 1) {
              persistClientAndRequest(
                (cachedAnswers as unknown) as RegistrationData
              )
                .then(() => history.push('anmeldung/abschliessen'))
                .catch(() => history.push('anmeldung/problem'));
            }
          }}
        >
          Abschicken
        </Control.Button>
        <Link to={location => ({ ...location, pathname: '/courses' })} />
        <Control.Button
          disabled={currentQuestion === questionsCount - 1}
          onClick={() =>
            setCurrentQuestion(
              Math.min(currentQuestion + 1, questionsCount - 1)
            )
          }
        >
          weiter
        </Control.Button>
      </div>
      <div className="register-questionnaire-question">
        <div className="register-questionnaire-question-wording text-xl xl:text-2xl">
          {questions[questionKey].wording}
        </div>
        <div className="register-questionnaire-question-field w-full">
          {questions[questionKey].field.type === 'select' && (
            <Control.Select
              className="text-center text-xl px-20 mt-4 xl:text-4xl"
              options={questions[questionKey].field.items || []}
              value={cachedAnswers[questionKey]}
              onChange={({ target }: ChangeEvent<any>) => {
                setCachedAnswers({
                  ...cachedAnswers,
                  [questionKey]: target.value,
                });
              }}
            />
          )}
          {questions[questionKey].field.type === 'text' && (
            <Control.Text
              className="text-center text-xl mt-4 w-full xl:text-4xl"
              value={cachedAnswers[questionKey] || ''}
              placeholder="deine Antwort hier.."
              onChange={({ target }: ChangeEvent<any>) =>
                setCachedAnswers({
                  ...cachedAnswers,
                  [questionKey]: target.value,
                })
              }
            />
          )}
          {questions[questionKey].field.type === 'checkbox' && (
            <Control.Check
              className="mt-4"
              value={cachedAnswers[questionKey] || ''}
              checked={cachedAnswers[questionKey] === 'ja'}
              onChange={({ target }: ChangeEvent<any>) =>
                setCachedAnswers({
                  ...cachedAnswers,
                  [questionKey]: target.checked ? 'ja' : 'nein',
                })
              }
            />
          )}
        </div>
      </div>
      <div className="register-questionnaire-question-details">
        {questions[questionKey].details || defaultDetails}
      </div>
    </div>
  );
};

export default Questionnaire;
