import firebase from '../firebase';

const addOrUpdateClient = (data: ClientWithRequest) => {
  const cleanField = (field: string) =>
    field.toLowerCase().match(/\w+/g)?.join('-');

  const reference = firebase
    .firestore()
    .collection('client')
    .doc(`${cleanField(data['Name'])}-${cleanField(data['Anschrift'])}`);

  return reference.set({
    ...data,
    modified: firebase.firestore.FieldValue.serverTimestamp(),
  });
};

export interface MailContent {
  subject: string;
  text: string;
  html?: string;
}

export interface Request {
  'Vorhandene Führerscheine': string;
  Führerscheinklasse: string;
  'Begleitetes Fahren': string;
  Sehhilfe: string;
}

export interface Client {
  Name: string;
  Anschrift: string;
  Staatsangehörigkeit: string;
  Geburtstag: string;
  Telefon: string;
  Email: string;
}

export type ClientWithRequest = Client & { Antrag: Request };

export const persistClientAndRequest = async (data: Client & Request) => {
  return addOrUpdateClient({
    Name: data['Name'] || '',
    Anschrift: data['Anschrift'] || '',
    Staatsangehörigkeit: data['Staatsangehörigkeit'] || '',
    Telefon: data['Telefon'] || '',
    Email: data['Email'] || '',
    Geburtstag: data['Geburtstag'] || '',
    Antrag: {
      Führerscheinklasse: data['Führerscheinklasse'] || '',
      'Vorhandene Führerscheine': data['Vorhandene Führerscheine'] || '',
      'Begleitetes Fahren': data['Begleitetes Fahren'] || 'nein',
      Sehhilfe: data['Sehhilfe'] || 'nein',
    },
  });
};
