import React, { useEffect, useRef } from 'react';
import backgroundJPG from 'assets/images/background.jpg';
import backgroundMP4 from 'assets/videos/background.mp4';
import backgroundOGV from 'assets/videos/background.ogv';
import backgroundWEBM from 'assets/videos/background.webm';

/**
 * This component is the background container. It statically renders an auto played and muted video.
 */
const Background = () => {
  const video = useRef<HTMLVideoElement>(null);
  useEffect(() => {
    if (video === null || video.current === null) return;
    else video.current.setAttribute('muted', '');
  });

  return (
    <div
      data-testid="background"
      className="background absolute h-9/12 xl:h-full xl:fixed xl:-z-10 xl:top-0 xl:bottom-0 xl:left-0 xl:right-0"
    >
      <video
        data-testid="background-video"
        className="background-video w-full h-full object-cover"
        poster={backgroundJPG}
        ref={video}
        playsInline
        autoPlay
        muted
        loop
      >
        <source src={backgroundMP4} type="video/mp4" />
        <source src={backgroundOGV} type="video/ogg" />
        <source src={backgroundWEBM} type="video/webm" />
      </video>
    </div>
  );
};

export default Background;
