import { AuthContext, RouteContext } from './Page.context';
import { Background, Footer, Header, Main } from 'containers';
import { PageEventHandler, PageProps, PageState } from './Page.types';
import React, { Component, Touch, WheelEvent } from 'react';
import { Route, Switch } from 'react-router-dom';
import views, { imprint } from 'views';
import { ScrollBar } from 'components';
import firebase from '../../firebase';
import { withRouter } from 'react-router';

/**
 * This component is the page container.
 * It is responsible for route management and transition.
 * Resulting properties are bundled in the {@link RouteContext}.
 * It is the the child containers responsibility to update according the current route information.
 *
 * @fixme loading takes to long.. due to big video
 */
class Page extends Component<PageProps, PageState> implements PageEventHandler {
  startTouch: Touch | undefined;
  transitionDuration: number;

  constructor(props: PageProps) {
    super(props);
    this.state = {
      blocked: false,
      isLoggedIn: false,
      index: Math.max(
        views.findIndex(view =>
          view.pathname.includes(props.history.location.pathname.split('/')[1])
        ),
        0
      ),
    };

    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.updateRoute = this.updateRoute.bind(this);
    this.onWheel = this.onWheel.bind(this);

    this.transitionDuration = window.matchMedia('(min-width: 1280px)').matches
      ? 1250
      : 500;
  }

  componentDidMount() {
    firebase
      .auth()
      .signInAnonymously()
      .then(
        () => this.setState({ isLoggedIn: true }),
        () => this.setState({ isLoggedIn: false })
      )
      .catch(() => this.setState({ isLoggedIn: false }));
  }

  updatePath(index: number) {
    this.props.history.replace(views[index].pathname);
  }

  updateRoute(nextRoute: number) {
    if (nextRoute > -1 && nextRoute < views.length) {
      this.setState({ blocked: true });

      let { index: currentRoute } = this.state;
      if (currentRoute < nextRoute) currentRoute += 1;
      if (currentRoute > nextRoute) currentRoute -= 1;

      this.updatePath(nextRoute);
      this.setState({ index: currentRoute }, () => {
        setTimeout(() => {
          if (currentRoute !== nextRoute) {
            this.updateRoute(nextRoute);
          } else {
            this.setState({ blocked: false });
          }
        }, this.transitionDuration);
      });
    }
  }

  onWheel(event: WheelEvent<HTMLDivElement>): void {
    const { index, blocked } = this.state;
    if (!blocked && event.deltaX === 0) {
      const goToNext = event.deltaY > 0;
      this.updateRoute(goToNext ? index + 1 : index - 1);
    }
  }

  onTouchMove(event: React.TouchEvent<HTMLDivElement>): void {
    const { index, blocked } = this.state;

    if (
      !blocked &&
      this.startTouch !== undefined &&
      Math.abs(this.startTouch.clientX - event.touches[0].clientX) < 5 &&
      Math.abs(this.startTouch.clientY - event.touches[0].clientY) > 30
    ) {
      const goToNext = this.startTouch.clientY > event.touches[0].clientY;
      this.updateRoute(goToNext ? index + 1 : index - 1);
    }
  }

  onTouchStart(event: React.TouchEvent<HTMLDivElement>): void {
    this.startTouch = event.touches[0];
  }

  render() {
    const { index, blocked, isLoggedIn } = this.state;
    return (
      <AuthContext.Provider value={{ isLoggedIn }}>
        <Switch>
          <Route path={imprint.pathname} component={imprint.component} />
          <div
            className="page w-full h-full fixed bg-black"
            onTouchStart={this.onTouchStart}
            onTouchMove={this.onTouchMove}
            onWheel={this.onWheel}
            data-testid="page"
          >
            <RouteContext.Provider
              value={{ index, blocked, update: this.updateRoute }}
            >
              <Header />
              <Background />
              <Main />
              <Footer />
              <ScrollBar
                width={(this.state.index / (views.length - 1)) * 100}
              />
            </RouteContext.Provider>
          </div>
        </Switch>
      </AuthContext.Provider>
    );
  }
}

export default withRouter(Page);
