import './intro.css';
import { Control, Paper } from 'components';
import React, { useEffect, useState } from 'react';
import { readStorage, updateStorage } from 'utils/storageHandler';
import { alertText } from './Intro.content';
import clsx from 'clsx';
import { createPortal } from 'react-dom';
import titlePNG from 'assets/images/title.png';

const Intro = () => {
  const allowCookies = readStorage()['cookies'] === 'true';
  const alerts = document.getElementById('alerts');

  const [showAlert, setShowAlert] = useState(!allowCookies);
  useEffect(() => {
    if (showAlert === false) updateStorage({ cookies: 'true' });
  }, [showAlert]);

  return (
    <>
      {alerts &&
        createPortal(
          <div
            data-testid="cookie-alert"
            className={clsx(
              'cookie-alert',
              'fixed bottom-0 z-50 w-full h-auto xl:h-1/12 p-3 xl:p-6 bg-black text-white text-justify text-sm',
              'flex justify-between xl:justify-around items-center',
              !showAlert && 'transform translate-y-full duration-1000'
            )}
          >
            <p className="mr-3 xl:mr-6">
              {alertText[0]}
              <br /> {alertText[1]}
            </p>
            <Control.Button outline onClick={() => setShowAlert(false)}>
              Verstanden
            </Control.Button>
          </div>,
          alerts
        )}

      <Paper
        data-testid="intro"
        className={clsx(
          'intro ml-auto',
          'bottom-4 absolute w-full xl:w-10/12 h-10/12 right-0'
        )}
      >
        <img
          className="h-full w-auto object-contain xl:w-full xl:object-contain"
          src={titlePNG}
          alt="background"
        />
      </Paper>
    </>
  );
};

export default Intro;
